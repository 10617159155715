
.nav-item-right {
  padding: 0 10rem 0 0;
}

.nav-item-left {
  padding: 0 0 0 10rem;
}

.app-container {
  justify-content: center;
  border: 3px solid #eee;
  border-radius: 10px;
  height: 80%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 2rem auto;
}

.container {
  padding: 20px;
}

.hr-welcome {
  width: 80%;
  margin: auto
}

.login-container {
  /* display: block; */
  border: 3px solid #eee;
  border-radius: 10px;
  height: 30%;
  width: 30%;
  position: absolute;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 10% auto;
}

.welcome-text {
  font-style: italic;
  font-weight: 300;
  margin-bottom: 30px;
}

.welcome {
  margin-top: 10px;
  font-weight: 100;
  font-style: italic;
}

.add-input {
  padding-right: 5px;
  width: 80%;
}

.icon {
  cursor: pointer;
}

.icons {
  visibility: hidden;
}

.icon-edit {
  font-size: 25px;
}

.icon-delete {
  font-size: 30px;
}

.icon-check {
  font-size: 34px;
  /* visibility: hidden; */
}

.text-make-selection {
  font-style: italic;
  color: #bbb
}
.list-name {
  cursor: pointer;
}

.addButton {
  width: 2em;
  height: 2em;
  position: relative;
}

.button-text {
  position: absolute;
  top: -25%;
  left: 0%;
  right: 0%;
}

.sidebar {
  padding-top: 10px;
}

#title-icons {
  background-color: black;

}

.icon-hamburger {
  position: relative;
  margin-left: 15px;
  margin-right: 0px;
  /* margin-bottom: -3px; */
  color: #888;
  font-size: 25px;
}

/* .list-entry {
  background-color: #0002;
} */


.list-hr-selected-top {
  height: 4px !important;
  margin-bottom: 13px;
}

.list-hr-selected-bottom {
  height: 4px !important;
  margin-top: 13px;
}

.disable-text-selection{
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}